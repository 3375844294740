/*
	*初始化侧滑菜单
	*window.onload后执行
 */
console.log("哈哈哈");
function rightMenuInit() {
    var $mask = $('#mask'),
        $rightBar = $('#rightBar'),
        $page = $('html,body');

    $('#menuImg').on('click',function () {
        $page.addClass('offcanvas').css({
            'min-width': $(window).width() + 'px',
            'min-height': $(window).height() + 'px'
        });

        $mask.removeClass('hidden');

        setTimeout(function () {
            $mask.addClass('fadeIn');
            $('.right_bar_content').css({
                backgroundColor: '#ffffff' //解决body=>overflow:hidden 后溢出部分没有背景的问题
            });
            $rightBar.css({
                transform: 'translateX(0)'
            });
        },10);
    });

    $('#leftBar,.close-search-mask').click(function () {
        $page.removeClass('offcanvas').css({
            // width: 'auto',
            // height: 'auto'
        });
        $rightBar.css({
            transform: 'translateX(100%)'
        }).one('transitionend',function () {
            setTimeout(function () {
                $mask.removeClass('fadeIn').one('transitionend',function () {
                    $(this).addClass('hidden');
                });
            },1);
        });
    });

    $('#toggleInfoOpen').on('click',function (){
        $('#maskInfoOpen').slideToggle('fast');
    });
}
/*
	*封装选项卡切换
	*需要在dom通过onclick调用，把this传过来
	*siblingsEle：tab的className
	*tabEle:选项卡的className
	*className:tab激活时的className，注意不要加"."
 */
function tab_switch(that,siblingsEle,tabEle,className) {
    $(siblingsEle).removeClass(className);
    $(that).addClass(className);
    $(tabEle).addClass('hidden').eq($(that).index()).removeClass('hidden');
}

// 图片幻灯片初始化
function magnificPopupInit(parentEle,childEle) {
    if(typeof $.fn.magnificPopup === 'undefined'){
        return false;
    }
    var st=0;
    $(parentEle).each(function (){
        $(this).magnificPopup({
            delegate: childEle, // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: {
                // options for gallery
                enabled: true
            },
            zoom: {
                enabled: true, // By default it's false, so don't forget to enable it

                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function
            },
            // other options
            callbacks:{
                beforeOpen:function () {
                    st = $(document).scrollTop();
                    // $('body').css({'margin-top':'-'+st+'px','position':'fixed'});
                },
                open:function () {
                    // $('.mfp-wrap').css('top',st+'px');
                    if($(parentEle).hasClass('image-glally')){
                        $('.mfp-img').css({
                            'max-height': 'unset'
                        });

                        $('.mfp-arrow').on('click',function () {
                            $('.mfp-img').css({
                                'max-height': 'unset'
                            });
                        })
                    }
                },
                afterClose:function () {
                    // $('body').css({'margin-top':'auto','position':'static'});
                    $(document).scrollTop(st);
                }
            }
        });
    })
}
jQuery(document).ready(function($){
    $('.hidden-content').each(function(){
        var $that = $(this);
        if($that.height() < (($that.data('height')-0) || 100)){
            $that.find('.view-more').hide();
            $that.removeClass('hidden-content');
        }
        else{
            $that.on('click','.view-more',function(){
                $that.find('.view-more').hide();
                $that.removeClass('hidden-content');
            });
        }
    });
})

function showMsg(info){
    $.msgBox({
        title: '提示',
        content: info
    })
}
function paramToObj(arr) {
    let newObj = {};
    arr.map(function(item, index) {
        newObj[item.name] = item.value
    });
    return newObj;
}
// 新的loadmore，滚动加载更多而不是点击加载更多
function newLoadMore (contentEle, url, params, options) {
    let $el = $(contentEle);

    let footerHeight = 0;
    if ($(".footer").length) {
        footerHeight = $(".footer").height();
    }

    let defOptions = {
        fnBefore(v){
            return v;
        },
        fnSuccess(){},
    };



    options = Object.assign({},defOptions, options);

    let defParams = {
        page: 2,
        // per_page: 10,
    };

    params = Object.assign({}, defParams,params);

    return $el.parent().dropload({
        scrollArea: window,
        loadDownFn : function(me){
            console.log("出现移动");
            params = options.fnBefore(params,me);
            $.ajax({
                type: 'GET',
                url,
                data: params,
                success: function (res) {
                    if(!$(res).find(contentEle).children().length){
                        me.lock();
                        me.noData();
                        me.resetload();
                        return false;
                    }
                    params.page++;
                    $el.append($(res).find(contentEle).html());
                    typeof options.fnSuccess === 'function' && options.fnSuccess(res);
                    setTimeout( ()=> {
                        // 每次数据加载完，必须重置
                        me.resetload();
                    })
                },
                error: function (e) {
                    me.resetload();
                }
            });
        },
        threshold: footerHeight || '',
        
    });
}

window.paramToObj = paramToObj;
window.newLoadMore = newLoadMore;


/*
    *receipt编辑抬头
*/
function addReceipt(url,data,cb) {
    $.ajax({
       url: url,
       type: 'post',
       data: data,
       success: function (res) {
           if(res.status === 0){
               $.msgBox({
                   title: '提示',
                   content: res.info
               })
           }else{
               if(cb){cb(res)}
               $.msgBox({
                   title: "提示",
                   content: res.info,
               });
           }
       }
   })
}




//js 数组去重
// Array.prototype.filterRepeatData = function (field) {
//     field = field||'id';
//     var temObj = {};
//     var that = this;
//     angular.forEach(this,function (v,i) {
//         if(v[field] in temObj){
//             // temObj[v[field]]++;
//             that.splice(i,1);
//         }else{
//             temObj[v[field]] = 0;
//         }
//     });
//     return this;
// };

if(!window.utils){
    window.utils = {};
}


//获取活动的tab 下的元素
function getActiveTabEle(tabContentSelector,targetSelector) {
    let $tabContentSelector = $(tabContentSelector).not('.hidden');
    return $tabContentSelector.find(targetSelector);
}

//全选
function selectAll(ele, selectList){
    let $ele = $(ele),
        $selectList = $(selectList);
   if($ele.prop('checked')){
       $selectList.find('input[type="checkbox"]').prop('checked'

           ,true);
   }else{
       $selectList.find('input[type="checkbox"]').prop('checked',false);
   }
}

function getSelectIds(ele){
    let $ele = $(ele),
        $inputEle = null,
        res = [];
    $ele.forEach((i,v)=>{
        $inputEle = $(v).find('input[type="checkbox"]');
        if($inputEle.attr('checked')){
            res.push($inputEle.val());
        }
    });
    return res;
}

function cancelEnterKey(){
    $(window).on('keydown',(e)=>{
        if(e.keyCode === 13){
            return false;
        }
    });
}

function isEnterKey(e){
    return e.keyCode === 13;
}

window.utils.getActiveTabEle = getActiveTabEle;
window.utils.selectAll = selectAll;
window.utils.getSelectIds = getSelectIds;
window.utils.cancelEnterKey = cancelEnterKey;


// 图片幻灯片初始化
function initMagnificPopup(parentEle, childEle = '.popup-item') {
    $(parentEle).magnificPopup({
        delegate: childEle, // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            // options for gallery
            enabled: true
        },
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it

            duration: 300, // duration of the effect, in milliseconds
            easing: 'ease-in-out', // CSS transition easing function
        }
        // other options
    });
}


function initSwiper(ele = '',confObj = {}) {
    if(!Swiper || typeof Swiper != 'function'){
        throw new Error('Swiper is require!');
    }
    var obj = {
        autoplay: 5000,//可选选项，自动滑动
        pagination : ele + ' .swiper-pagination',
        paginationClickable :true,
        nextButton: ele + ' .swiper-next',
        prevButton: ele + ' .swiper-prev',
        loop : true,
    };
    obj = $.extend({}, obj,confObj);
    return new Swiper(ele, obj);
}

//计算图片比例
function scalePic(originWidth,originHeight,currentWidth) {
    let scale = currentWidth / originWidth;
    return originHeight * scale;
}


//计算图片比例
function scalePicHeight(originWidth,originHeight,currentHeight) {
    let scale = currentHeight / originHeight;
    return originWidth * scale;
}

window.utils.toFixedNumber = function(number,compareVal) {
    if(number < compareVal){
        number = String(number);
        compareVal = String(compareVal);
        let counter = compareVal.length - number.length;
        for(var i = counter;i > 0;i--){
            number = '0' + number;
        }
    }
    return number;
};

function getEventEmitter(name) {
    let eventInstance = window.EventEmitter[name];
    console.log('1');
    if(eventInstance){
        return eventInstance;
        console.log('2');
    }
    console.log('3');
    return window.EventEmitter[name] = new TinyEmitter();
}

window.getEventEmitter = getEventEmitter;
